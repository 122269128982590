.widget-badge {
  .widget-badge-inner {
    &.has-animation {
      transition: 250ms transform ease-in-out;

      &:hover {
        transform: translateY(-7px);
      }
    }
  }

  .widget-badge-big {
    svg {
      width: 25px;
      height: 25px;
    }
  }
}
