@media (max-width: 1024px) {
  .ManageFilters {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: -2;
    opacity: 0;
    // z-index: 99;
    transition: opacity 250ms ease-in-out, z-index 250ms ease-in-out;
    // transform: translateY(110%);

    .manage-content {
      height: max-content;
      max-height: 550px;
      overflow-y: scroll;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      position: absolute;
      bottom: 0;
      width: 100%;
      transition: transform 250ms ease-in-out;
      transform: translateY(100%);
    }

    &.active {
      z-index: 49;
      opacity: 1;
      .manage-content {
        transform: translateY(0);
      }
    }

    .manage-line {
      height: 5px;
      width: 60px;
      background: #94a3b8;
      border-radius: 10px;
      display: block;
      margin: 0 auto 19px;
    }
  }
}
