.widget-card-centered {
  // height: 220px;
  position: relative;
  margin-top: 35px;
  height: 100%;

  .widget-img {
    margin: -35px auto 0;
    position: relative;

    .platform-icon {
      position: absolute;
      bottom: 1px;
      right: 1px;
      background: #fff;
      border-radius: 50%;
    }
  }

  &.has-animation {
    transition: 250ms transform ease-in-out;

    &:hover {
      transform: translateY(-7px);
    }
  }

  &__description {
    height: 88px;
    overflow-y: scroll;
    padding-right: 10px;

    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}
