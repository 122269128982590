#trustbucket-slider-1 {
  height: 420px;
}

#trustbucket-carousel {
  height: 330px;

  @media (max-width: 1024px) {
    height: 460px;
  }
}

#trustbucket-grid-1 {
  height: 620px;

  @media (max-width: 1024px) {
    height: 880px;
  }

  @media (max-width: 640px) {
    height: 1100px;
  }
}
