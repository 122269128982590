.email-content {
  p {
    min-height: 22px;
    &:last-of-type {
      min-height: auto;
    }
  }
  h1 {
    font-size: 2em;
    font-weight: bold;
    min-height: 22px;
  }
  h3 {
    font-size: 1.17em;
    font-weight: bold;
    min-height: 22px;
  }
  h2 {
    font-weight: bold;
    min-height: 22px;
    font-size: 1.5em;
  }
  h4,
  h5,
  h6 {
    font-weight: bold;
    min-height: 22px;
    font-size: 15px;
  }
}
