.FreshaStars {
  margin: 10px 0px;
  span {
    background-size: 18px;
    width: 18px;
    height: 18px;
    margin-right: 3px;
    &.rated {
      background-image: url("../../icons/fresha-star-fill.svg");
    }

    &.not-rated {
      background-image: url("../../icons/fresha-star-not-fill.svg");
    }
  }
}
