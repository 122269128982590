.widget-card {
  height: 274px;

  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &.has-animation {
    transition: 250ms transform ease-in-out;

    &:hover {
      transform: translateY(-7px);
    }
  }

  &__title {
    height: 29px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  &__description {
    height: 95px;
    overflow-y: scroll;
    padding-right: 10px;

    &.has-title {
      height: 78px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      border-radius: 10px;
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 7px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: none;
      background-color: #ccc;
    }

    // &::-webkit-scrollbar {
    //   width: 5px;
    // }

    // /* Track */
    // &::-webkit-scrollbar-track {
    //   background: transparent;
    // }

    // /* Handle */
    // &::-webkit-scrollbar-thumb {
    //   background: #888;
    //   border-radius: 10px;
    // }

    // /* Handle on hover */
    // &::-webkit-scrollbar-thumb:hover {
    //   background: #555;
    // }
  }
}

@media (max-width: 768px) {
  .widget-card {
    height: 284px;
  }
}
