.widget-slider {
  position: relative;

  .swiper-container {
    padding: 7px 4px !important;
    width: 100%;
  }

  .widget-slider-arrow {
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #d6dae4;
    border-radius: 50%;
    padding: 6px;
    margin-left: 5px;
    transition: 200ms border-color ease;
    svg {
      width: 16px;
      height: 16px;
      transition: color 250ms ease;
    }

    &.arrow-prev {
      svg {
        transform: rotate(180deg);
      }
      margin-right: 5px;
      margin-left: 0;
    }

    &:hover {
      border-color: #2563eb;

      svg {
        color: #000;
      }
    }
  }

  .widget-slider-pagination {
    display: flex;
    justify-content: center;
    margin-top: 12px;

    &.dots--hide,
    &.dots--mobile-only {
      visibility: hidden;
      height: 0;
    }

    .swiper-pagination-bullet {
      background: #ccc !important;
      opacity: 0.4;
      width: 11px;
      height: 11px;
      margin: 0 3px;

      &.swiper-pagination-bullet-active {
        opacity: 1;
      }
    }
  }
}
.carousel-left {
  svg {
    width: 24px;
    height: 24px;
  }
}

@media (max-width: 768px) {
  .widget-slider {
    // .widget-slider-arrow {
    //   padding: 0;
    //   &.arrow-prev {
    //     padding-right: 7px;
    //   }

    //   &.arrow-next {
    //     padding-left: 7px;
    //   }
    // }
    .widget-slider-pagination {
      &.dots--mobile-only {
        visibility: visible;
        height: max-content;
      }
    }
  }
}

.widget-carousel {
  width: calc(100% - 357px);

  @media (max-width: 1024px) {
    width: 100%;
  }
}
